import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";

export const Research: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPublishedResearch(sort: { fields: date }) {
        edges {
          node {
            date(formatString: "MM/YYYY")
            publicationUrl
            title
          }
        }
      }
    }
  `);
  const publishedResearch = data?.allContentfulPublishedResearch?.edges;

  return (
    <div className="bg-gray-50" id="research">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 border-b border-blue-900">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-900">
              Published Research
            </h2>
            {/* <p className="text-xl text-gray-700">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio
              vitae elementum enim vitae ullamcorper suspendisse. Vivamus
              fringilla.
            </p> */}
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              {publishedResearch.map(({ node }) => (
                <li
                  key={node.title}
                  className="relative rounded-md border border-none bg-blue-50 hover:bg-blue-100 px-6 py-5 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                >
                  <div className="flex-1 min-w-0">
                    <a
                      href={node.publicationUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-xl font-medium text-blue-600 underline">
                        {node.title}
                      </p>
                      {/* {node.publication && (
                        <p className="text-md text-gray-900 truncate">
                          {node.publication}
                        </p>
                      )} */}
                      <p className="text-lg text-blue-900 mt-2">{node.date}</p>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
