import { PageProps } from "gatsby";
import React, { FC } from "react";

import { SmallPicHero } from "../indexPage/SmallPicHero";
import { Research } from "../indexPage/Research";
import { Education } from "../indexPage/Education";
import { Layout } from "../shared/components/Layout";
import { FAQ } from "../indexPage/FAQ";

const IndexPage: FC<PageProps> = ({ location }) => {
  const schema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "Syed Ali Husain",
    url: "https://syedalihusain.netlify.app/",
  };

  return (
    <Layout
      seo={{
        // schemaMarkup: schema,
        url: "https://syedalihusain.netlify.app/",
        description:
          "Dr. Syed Ali Husain, Nephrologist. Specialist in kidney research and kidney medicine",
        title: "Home | Dr. Syed Ali Husain",
        keywords: [
          "Kidney",
          "Kidney Medicine",
          "Internal Medicine",
          "MD",
          "Doctor",
          "Nephrologist",
          "Nephrology",
        ],
      }}
      location={location}
    >
      <div className="min-h-screen">
        <SmallPicHero />
        <Education />
        <Research />
        <FAQ />
      </div>
    </Layout>
  );
};

export default IndexPage;
