import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

const RICHTEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <span className="font-extrabold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return <p style={{ marginBottom: 10 }}>{children}</p>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        className="text-blue-700 underline"
        href={data.uri}
        target={`${data.uri.startsWith("salihusain") ? "_self" : "_blank"}`}
        rel={`${
          data.uri.startsWith("salihusain") ? "" : "noopener noreferrer"
        }`}
      >
        {children}
      </a>
    ),
  },
};

const people = [
  {
    name: "Leslie Alexander",
    role: "Co-Founder / CEO",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  // More people...
];

export const SmallPicHero: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulAbout(contentful_id: { eq: "6Wtp00UO7YIYLba4uV7cDK" }) {
        id
        title
        updatedAt(formatString: "LL")
        contentful_id
        body {
          raw
        }
      }
    }
  `);

  const { body } = data?.contentfulAbout;
  const json = JSON.parse(body.raw);

  return (
    <div className="bg-gray-50">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 border-b border-blue-900">
        <div className="space-y-12 sm:flex sm:justify-start">
          <div className="space-y-5 sm:space-y-4 lg:w-2/3">
            <h2 className="text-3xl font-extrabold tracking-tight text-center md:text-left sm:text-4xl text-blue-900">
              About
            </h2>
            <p className="text-xl lg:text-2xl text-gray-700 sm:pr-6 lg:pr-32 sm:text-left text-center">
              {documentToReactComponents(json, RICHTEXT_OPTIONS)}
            </p>
          </div>
          <div className="">
            <div className="space-y-4 sm:gap-6 sm:space-y-0">
              <div className="pb-2 lg:pb-0 lg:w-64 lg:h-auto">
                <StaticImage
                  className="object-cover rounded-lg z-10"
                  src="../images/headshot.jpg"
                  alt="Doctor"
                  placeholder="dominantColor"
                />
              </div>
              <div className="">
                <div className="space-y-4">
                  <div className="text-xl leading-6 font-medium space-y-0 pt-3">
                    <h3>Dr. Syed Ali Husain</h3>
                    <p className="text-blue-900">Nephrologist</p>
                  </div>
                  {/* <div className="text-lg">
                    <p className="text-gray-500">{person.bio}</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
