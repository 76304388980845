import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

const RICHTEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <span className="font-extrabold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return <p style={{ marginBottom: 10 }}>{children}</p>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        className="text-blue-700 underline"
        href={data.uri}
        target={`${data.uri.startsWith("salihusain") ? "_self" : "_blank"}`}
        rel={`${
          data.uri.startsWith("salihusain") ? "" : "noopener noreferrer"
        }`}
      >
        {children}
      </a>
    ),
  },
};

export const FAQ: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  const faqs = data?.allContentfulFaq?.edges;

  return (
    <div className="bg-gray-50" id="faq">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-blue-900">
          Frequently asked questions
        </h2>
        <div className="mt-6 border-t border-gray-400 border-opacity-25 pt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
            {faqs.map(({ node }) => (
              <div key={node.question.question}>
                <dt className="text-xl leading-6 font-medium textgray-900">
                  {node.question.question}
                </dt>
                <dd className="mt-2 text-lg text-gray-700">
                  {documentToReactComponents(
                    JSON.parse(node.answer.raw),
                    RICHTEXT_OPTIONS
                  )}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
