import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { FC } from "react";

export const Education: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEducation(sort: { fields: end, order: ASC }) {
        edges {
          node {
            end(formatString: "MM/YYYY")
            degree
            createdAt
            start(formatString: "MM/YYYY")
            school
            schoolLogo {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const education = data?.allContentfulEducation?.edges;
  return (
    <div className="bg-gray-50" id="education">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24 border-b border-blue-900">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-900">
              Education
            </h2>
            {/* <p className="text-xl text-gray-700">
              Libero fames augue nisl porttitor nisi, quis. Id ac elit odio
              vitae elementum enim vitae ullamcorper suspendisse. Vivamus
              fringilla.
            </p> */}
          </div>
          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
              {education.map(({ node }) => (
                <li key={node.degree}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <GatsbyImage
                      className="w-1/3 h-1/3 lg:w-1/3 lg:h-1/3 rounded-lg"
                      image={node.schoolLogo.gatsbyImageData}
                      alt={node.school}
                    />

                    <div className="font-medium text-lg leading-6 space-y-1 w-2/3">
                      <h3>{node.school}</h3>

                      <div className="flex flex-col">
                        <p className="text-gray-800 text-base">
                          {node.degree}{" "}
                        </p>

                        <p className="text-gray-700 text-sm">
                          {node.start} - {node.end}
                        </p>
                      </div>
                      {/* <p className="text-gray-900">{node.degree}</p> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
